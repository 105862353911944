import { Link, UIMatch, generatePath } from 'react-router-dom'

import { DynamicBreadcrumb } from 'routes/breadcrumbs'
import { routePaths } from 'routes/route-paths'
import { CsRoute } from 'routes/types'
import { prepareRoutes } from 'routes/utils/prepareRoutes'

export const usePayGroupRoutes = (): CsRoute => {
	return {
		path: `${routePaths.organizations.edit.deductionGroups.path}/*`,
		handle: {
			crumb: (data: UIMatch<unknown, unknown>) => {
				return (
					<Link
						to={generatePath(routePaths.organizations.edit.deductionGroups.path, {
							id: data.params.id ?? '',
						})}
					>
						Pay Groups
					</Link>
				)
			},
		},
		children: [
			{
				index: true,
				lazy: () =>
					import('pages/organizations/pay-groups/organization-pay-groups').then((module) => ({
						Component: module.default,
					})),
			},
			...prepareRoutes(
				[
					{
						path: `${routePaths.organizations.edit.deductionGroups.schedules.path}/*`,
						lazy: () =>
							import('pages/paygroup-schedules/paygroup-schedules-page').then((module) => ({
								Component: module.default,
							})),
						handle: {
							crumb: (data: UIMatch<any, any>) => {
								return <DynamicBreadcrumb portalId={data.params.payGroupId ?? ''} />
							},
						},
					},
				],
				{
					stripLeading: `${routePaths.organizations.edit.deductionGroups.path}/`,
				},
			),
		],
	}
}
