import './form.scss'

import React, { useCallback, useState } from 'react'

import FileIcon from '@material-ui/icons/InsertDriveFileOutlined'
import { cx } from 'class-variance-authority'
import Text from 'components/text'
import { useDropzone } from 'react-dropzone'
import { convertMbToB } from 'utils/util'
import { FileType } from './util'

interface UploaderProps {
	acceptedTypes?: string[]
	containerClassName?: string | null
	inputErrorMessage?: string
	handleDrop: (acceptedFiles, name?) => void
	helperText?: string | React.ReactNode
	label?: string
	previewLabel?: string
	maxSize?: number
	name: string
	multiple?: boolean
	image?: string
	useSmallTextLabel?: boolean
	disableThumbnails?: boolean
	disableImagePreview?: boolean
}

const FormUploader: React.FC<UploaderProps> = ({
	acceptedTypes,
	containerClassName,
	handleDrop,
	helperText,
	image,
	inputErrorMessage,
	label,
	previewLabel,
	maxSize,
	name,
	multiple,
	useSmallTextLabel,
	disableThumbnails,
	disableImagePreview,
}: UploaderProps) => {
	const [files, setFiles] = useState<FileType[]>([])
	const onDrop = useCallback(
		(acceptedFiles): void => {
			setFiles(acceptedFiles.map((file) => ({ ...file, preview: URL.createObjectURL(file), type: file.type })))
			const files = multiple ? acceptedFiles : acceptedFiles[0]
			handleDrop(files, name)
		},
		[handleDrop, multiple, name],
	)
	const { fileRejections, getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
		accept: acceptedTypes?.map((type): string => `${type}`).join(', '),
		maxSize,
		multiple: !!multiple,
		onDrop,
	})

	const errorMessage: React.ReactElement = (
		<div className='error-message'>
			<p>Invalid file type or file size.</p>
			<p>
				The accepted types are: {acceptedTypes?.join(', ')} and the max file size is:{' '}
				{convertMbToB(maxSize ?? 10485760)}MB. Please try again.
			</p>
		</div>
	)

	const renderDragMessage = (isDragActive: boolean, isDragReject: boolean): React.ReactElement => {
		if (isDragActive) {
			return <p>Drop the files here</p>
		}

		if (isDragReject || fileRejections.length) {
			return errorMessage
		}

		return <p>Drag and drop here, or click to select files</p>
	}

	const getFileType = (mime: string): 'image' | 'video' | 'other' => {
		const rootMime = mime.split('/')[0]
		if (rootMime === 'image' || rootMime === 'video') return rootMime

		return 'other'
	}

	const thumbs = files.map((file, index: number): React.ReactElement => {
		const type = getFileType(file.type)

		return (
			<div className='thumb' key={`${file.name}-${index}`}>
				<div className='thumb-inner'>
					{type === 'image' ? (
						<img src={file.preview} className='img' alt={file.name} />
					) : (
						<FileIcon fontSize='large' />
					)}
				</div>
			</div>
		)
	})

	const getProvidedImage = (image): React.ReactNode => {
		if (image) {
			return (
				<aside className='thumbs-container'>
					<div className='thumb'>
						<div className='thumb-inner'>
							<img src={image} className='img' alt={image} />
						</div>
					</div>
				</aside>
			)
		} else {
			return <div />
		}
	}

	return (
		<section className={containerClassName ?? undefined}>
			<label>
				{label && useSmallTextLabel ? <Text variant='body2'>{label}</Text> : <Text variant='h6'>{label}</Text>}
			</label>
			<div {...getRootProps({ className: cx({ ['input-error']: inputErrorMessage }, 'upload-container') })}>
				<input name={name} {...getInputProps()} />
				{renderDragMessage(isDragActive, isDragReject)}
			</div>
			{inputErrorMessage && <div className={'input-error-message'}>{inputErrorMessage}</div>}
			{!disableImagePreview && (files.length || image) ? (
				<label>
					{previewLabel && useSmallTextLabel ? (
						<Text variant='body2'>{previewLabel}</Text>
					) : previewLabel ? (
						<Text variant='h6'>{previewLabel}</Text>
					) : useSmallTextLabel ? (
						<Text variant='body2'>Image Preview</Text>
					) : (
						<Text variant='h6'>Image Preview</Text>
					)}
				</label>
			) : null}
			{helperText && (
				<p className='helper-text' style={{ margin: 'var(--space-m) 0 0 0' }}>
					{helperText}
				</p>
			)}
			{!disableThumbnails &&
				(files.length ? <aside className='thumbs-container'>{thumbs}</aside> : getProvidedImage(image))}
		</section>
	)
}

FormUploader.defaultProps = {
	acceptedTypes: ['image/*'],
	containerClassName: 'container',
	maxSize: 10485760, // 10MB
}

export default FormUploader
