import { queryOptions, useQuery as useTanstackQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { UseQueryResult, useQuery } from 'react-query'
import { Rates } from 'types/tenants/rates'
import { queryKeys } from '../keys'
import { RatesService } from '../services/rates-service'

export const useGetRates: (props: {
	benefitPlanId: string
	rateInfoId: string | null
	enabled?: boolean
	onSuccess?: (existingRates: Rates) => void
}) => UseQueryResult<Rates, AxiosError<any>> = ({ benefitPlanId, enabled = true, onSuccess, rateInfoId }) =>
	useQuery<Rates, AxiosError<any>>(
		queryKeys.benefitPlans.rates.getByRateInfoId(rateInfoId ?? ''),
		() =>
			rateInfoId
				? RatesService.getRateById(benefitPlanId, rateInfoId)
				: RatesService.getMostRecentVersion(benefitPlanId),
		{
			enabled,
			onSuccess,
		},
	)

export const useGetRatesTanstack = (props: { benefitPlanId: string; rateInfoId: string | null; enabled?: boolean }) => {
	const query = useTanstackQuery({
		...getRatesOptions(props),
		enabled: props.enabled,
	})

	return query
}

function getRatesOptions(props: { benefitPlanId: string; rateInfoId: string | null }) {
	return queryOptions({
		queryKey: queryKeys.benefitPlans.rates.getByRateInfoId(props.rateInfoId ?? ''),
		queryFn: () =>
			props.rateInfoId
				? RatesService.getRateById(props.benefitPlanId, props.rateInfoId)
				: RatesService.getMostRecentVersion(props.benefitPlanId),
	})
}
