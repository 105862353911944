import { GridSortDirection, GridSortItem } from '@material-ui/data-grid'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import * as clipboard from 'clipboard-polyfill/text'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { StateMachineStatus } from 'hooks/use-async-fetch'
import { Category } from 'types/tenants/category'
import { CorestreamProduct } from 'types/tenants/corestream-product'
import * as yup from 'yup'

export const specialCharacters: RegExp = /[\s~`!@#$%^&*+=\-[\]\\';,/{}|\\":<>?()._]/ //` //ADO confused by open tick
export const upperCase: RegExp = /[A-Z]/
export const lowerCase: RegExp = /[a-z]/
export const digits: RegExp = /[0-9]/

/** @deprecated use convertMegaBytesToBytes instead */
export const convertMbToB = (num: number): number => num / 1024 / 1024
/** @deprecated use convertBytesToMegaBytes instead */
export const convertBtoMb = (num: number): number => num * 1024 * 1024

export const convertMegaBytesToBytes = (num: number): number => num * 1024 * 1024
export const convertBytesToMegaBytes = (num: number): number => num / 1024 / 1024

export const isMaxDate = (date: Date | string = ''): boolean => new Date(date) >= new Date('12/31/9999')

function ensure<T>(argument: T | undefined | null, message: string = 'This value was promised to be there.'): T {
	if (argument === undefined || argument === null) {
		throw new TypeError(message)
	}

	return argument
}
/**
 *
 * @param startDate Date to check against
 * @returns boolean
 */
export const isStartDateInPast = (startDate: Date | string | null): boolean => {
	const now = new Date().setHours(0, 0, 0, 0)
	if (!startDate) {
		return false
	}
	if (typeof startDate === 'string') {
		return new Date(startDate).getTime() < now
	}

	return startDate.getTime() < now
}

/**
 *
 * @param date Date to set hours as midnight for
 * @returns Date with time at midnight
 */
export function setTimeToMidnight(date: Date | string): Date {
	if (typeof date === 'string') {
		return new Date(date)
	}

	const dateClone = new Date(date.getTime())
	dateClone.setUTCHours(0, 0, 0, 0)

	return dateClone
}

/**
 *
 * @param date Date to set time to end of day
 * @returns Date a day later
 */
export function setTimeToEndOfDay(date: Date | string): Date {
	if (typeof date === 'string') {
		date = new Date(date + 'Z')
	}
	date.setUTCHours(23, 59, 59)

	return date
}

export const setStartAndEndOfDayInLocalTime = (selectedDate: string) => {
	const endDate = new Date(selectedDate)
	const startDate = new Date(selectedDate)
	startDate.setHours(0, 0, 0, 0)
	endDate.setHours(23, 59, 59, 999)

	return { endDate, startDate }
}

export function addDays(date: Date | string = '', days: number = 0): Date {
	const maxDate = new Date('12/31/9999')
	const minDate = new Date('1/1/0001')
	const newDate = date ? new Date(date) : new Date()
	newDate.setDate(newDate.getDate() + days)

	if (newDate > maxDate) return maxDate
	else if (newDate < minDate) return minDate
	else return newDate
}

/**
 * Converts date from UTC to local time
 * @param date Date to convert
 * @returns Date converted from UTC to local time
 */
export function convertUTCDateToLocalDate(date: Date): Date {
	return new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000)
}
/**
 * Formats date in format: Month Day Year: August 1 2020
 * Converts the date to local time first
 * @param date - Date to format
 * @param customFormat - a custom date format to use instead. ex. 'MM/DD/YYYY'
 */
export function formatDate(date: string | Date = '', customFormat: string = ''): string {
	const updatedDate = date ? convertUTCDateToLocalDate(new Date(date)) : new Date()

	return dayjs(updatedDate).format(customFormat || 'MMM DD YYYY')
}
/**
 * Formats date in format: Month Day Year: August 1 2020
 * Does not convert to local time
 * @param date - Date to format
 * @param customFormat - a custom date format to use instead. ex. 'MM/DD/YYYY'
 */
export function formatDateWithoutLocalConversion(date: string | Date = '', customFormat: string = ''): string {
	const updatedDate = date ? new Date(date) : new Date()

	return dayjs(updatedDate).format(customFormat || 'MMM DD YYYY')
}

export function formatUtcDateWithoutConversion(date: string | Date, customFormat: string = 'MMM DD YYYY'): string {
	dayjs.extend(utc)

	return dayjs.utc(date).format(customFormat)
}

export function formatDateAndTime(date: Date): string {
	const updatedDate = date ? convertUTCDateToLocalDate(new Date(date)) : new Date()
	const day = updatedDate.getDate().toString()
	const month = updatedDate.toLocaleString('en-us', { month: 'short' })
	const year = updatedDate.getFullYear().toString()

	const hours = updatedDate.getHours()
	const minutes = String(updatedDate.getMinutes()).padStart(2, '0')
	const seconds = String(updatedDate.getSeconds()).padStart(2, '0')

	return `${month} ${day} ${year} ${hours}:${minutes}:${seconds}`
}

export function formatDateAndTimeWithoutLocalConversion(date: Date): string {
	const updatedDate = date ? new Date(date) : new Date()
	const day = updatedDate.getDate().toString()
	const month = updatedDate.toLocaleString('en-us', { month: 'short' })
	const year = updatedDate.getFullYear().toString()

	const hours = updatedDate.getHours()
	const minutes = String(updatedDate.getMinutes()).padStart(2, '0')
	const seconds = String(updatedDate.getSeconds()).padStart(2, '0')

	return `${month} ${day} ${year} ${hours}:${minutes}:${seconds}`
}

export function formatRateDate(date: string | Date = '', separator: string | undefined = ' '): string {
	const updatedDate = new Date(date)
	const day = updatedDate.getDate().toString()
	const month = updatedDate.toLocaleString('en-us', { month: 'short' })
	const year = updatedDate.getFullYear().toString()

	return `${month}${separator}${day}${separator}${year}`
}

export function formatDateString(date: string): string {
	if (!date) return ''
	const newDate = date.split('T')[0]
	if (!newDate) return date

	const dateParts = newDate.split('-')
	if (dateParts.length !== 3) return date

	return `${dateParts[1]}/${dateParts[2]}/${dateParts[0]}`
}

/**
 * This is responsible for formatting a string (which can contain breakpoints/new lines) into
 * an unordered list
 */
export const formatBulletedList = (value: string) =>
	value
		.trim() // Remove empty spaces
		.replace(/(<br \/>)|(<\/li>)/g, '\n') // Replace any break tags with new lines
		.replace(/<[^>]*>?/gm, '') // Remove any tags
		.replace(/[^\r\n]+/g, '<li>$&</li>') // Convert each line into its own <li>

export const formatBool = (value: boolean | number): string => (value === true || value === 1 ? 'Yes' : 'No')

/**
 * Gets the current running environment from the window
 */
export function getEnv(): string {
	const hostname = window && window.location && window.location.hostname
	const LOCAL = 'local'
	const DEV = 'dev'
	const INT = 'int'
	const PROD = 'prod'
	const UAT = 'test'

	if (hostname.includes(DEV) || hostname.includes(LOCAL)) return DEV
	if (hostname.includes(INT)) return INT
	if (hostname.includes(UAT)) return UAT

	return PROD
}

/**
 * Updates isSubmitting flag if state is error or success
 * @param status State machine status - loading, idle, error, success states
 */
export function updateIsSubmitting(status: StateMachineStatus, setIsSubmitting): void {
	if (status === 'error' || status === 'success') {
		setIsSubmitting(false)
	}
}

export function mapCategoryToId(categories: Category[] | null, categoryName: string): number {
	const selectedCategory = ensure(categories?.find((category) => category.name === categoryName))

	return selectedCategory.categoryId
}

export function mapIdToCategoryName(categories: Category[] | null, categoryId: number): string {
	const selectedCategory = ensure(categories?.find((category) => category.categoryId === categoryId))

	return selectedCategory?.name
}

export const formatCorestreamProducts = (
	corestreamProducts: CorestreamProduct[] | undefined,
): Array<{ id: string; value: string }> =>
	corestreamProducts?.map((csProduct: CorestreamProduct) => ({
		id: csProduct.corestreamProductId,
		value: csProduct.productName,
	})) ?? []

export function formatMoney(number: number): string {
	if (number == null) return ''

	return number.toLocaleString('en-US', { currency: 'USD', style: 'currency' })
}

/**
 * Map of corestreamPayGroups to their displayable string literal counterparts.
 */
export const periodMap: Record<string, string> = {
	BW: 'Bi-Weekly',
	MO: 'Monthly',
	SM: 'Semi-Monthly',
	WK: 'Weekly',
}

// conver getPasswordError to a yup valiation rule
export const passwordValidation = yup
	.string()
	.required('Password is required.')
	.min(6, 'Password must be at least 6 characters.')
	.matches(lowerCase, 'Password must contain at least one lowercase character.')
	.matches(upperCase, 'Password must contain at least one uppercase character.')
	.matches(specialCharacters, 'Password must contain at least one special character.')
	.matches(digits, 'Password must contain at least one number.')

export class LocalStore {
	static get<T>(key: string): T | null {
		const item: string | null = localStorage.getItem(key)
		if (!item) return null

		const parsedItem: T = JSON.parse(item)

		return parsedItem
	}

	static set<T>(key: string, item: T): void {
		localStorage.setItem(key, JSON.stringify(item))
	}

	static remove(key: string): void {
		localStorage.removeItem(key)
	}
}

export function copyToClipboard(value: string): Promise<void> {
	return clipboard.writeText(value)
}

export function normalize(array, keyToNormalizeBy: string) {
	return array.reduce((byId, item) => ({ ...byId, [item[keyToNormalizeBy]]: item }), {})
}

const areaCodeAndNextThree = (value: string): string => `(${value.slice(0, 3)}) ${value.slice(3)}`

/**
 * @param {string} value - current phone input value
 * @returns {string} '(xxx) xxx-', (xxx) xxx-x', '(xxx) xxx-xx', '(xxx) xxx-xxx', '(xxx) xxx-xxxx'
 */
const finalPhoneNumber = (value: string): string => `(${value.slice(0, 3)}) ${value.slice(3, 6)}-${value.slice(6, 10)}`

// only allows 0-9 inputs
export const convertStringToNumber = (value: string): string => value.replace(/[^\d]/g, '')

export const formatSsn = (value: string): string => {
	const firstFiveSsn = (val: string): string => `${val.slice(0, 3)}-${val.slice(3, 5)}`
	const finalSsn = (val: string): string => `${val.slice(0, 3)}-${val.slice(3, 5)}-${val.slice(5, 9)}`
	const currentValue: string = convertStringToNumber(value)

	if (currentValue.length < 4) {
		return currentValue
	}
	if (currentValue.length < 6) {
		return firstFiveSsn(currentValue)
	}

	return finalSsn(currentValue)
}

export const formatPhoneNumber = (value: string): string => {
	const currentValue: string = convertStringToNumber(value)

	if (currentValue.length < 4) {
		return currentValue
	}

	if (currentValue.length < 7) {
		return areaCodeAndNextThree(currentValue)
	}

	return finalPhoneNumber(currentValue)
}

export const formatZipCode = (value: string): string => {
	const addHyphen = (val) => `${val.slice(0, 5)}-${val.slice(5)}`
	if (value.length > 5 && !value.includes('-')) {
		return addHyphen(value)
	}

	return value
}

export const isValidEmail = (value: string): boolean => {
	const yupString = yup.string()

	return yupString.email().isValidSync(value)
}

export const convertStringToNumberOrNull = (value: string): number | null => {
	const number = Number(value)
	if (value === '' || value === null || isNaN(number)) return null

	return number
}

export const addSpaceBetweenCapitalLetters = (value: string): string => value.replace(/([A-Z])/g, ' $1').trim()

export const removeSpacesInAString = (value: string): string => value.replace(/\s/g, '')

export const clearDateIfNoValueIsSelected = (date: string | MaterialUiPickersDate) => {
	if (date === '') return null

	return date
}

export const handleTableSortingModel = (
	field: string | undefined,
	order: GridSortDirection | undefined,
): GridSortItem[] => {
	if (field) {
		return [{ field: field, sort: order }]
	}

	return []
}

// Sequence generator function (commonly referred to as "range", e.g. Clojure, PHP, etc.)
// source https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/from#sequence_generator_range
// create a range of numbers
export const range = (start: number, stop: number, step: number = 1): number[] =>
	Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step)

// map a function over a range of numbers
export const mapRange = <MapTo>(start: number, stop: number, step: number, mapper: (i: number) => MapTo): MapTo[] =>
	Array.from({ length: (stop - start) / step + 1 }, (_, i) => mapper(start + i * step))

// generate select items for a list of numbers for a "Days" select control
export const generateSelectItemNumbersForRange = (start: number, stop: number) =>
	mapRange(start, stop, 1, (i) => ({ id: i.toString(), value: i.toString() }))
