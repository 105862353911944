/* eslint-disable max-len */
export const routePaths = {
	login: {
		path: '/login',
	},
	benefitProviders: {
		path: '/benefit-providers',
		create: '/benefit-providers/create',
		edit: {
			products: {
				path: '/benefit-providers/edit/:id/products',
				create: {
					path: '/benefit-providers/edit/:id/products/create',
				},
				edit: {
					root: '/benefit-providers/edit/:id/products/edit/:benefitProductId',
					details: {
						path: '/benefit-providers/edit/:id/products/edit/:benefitProductId/details',
						localTabPath: 'details',
					},
					benefitPlans: {
						path: '/benefit-providers/edit/:id/products/edit/:benefitProductId/benefitplans',
						localTabPath: 'benefitplans',
					},
					templates: {
						path: '/benefit-providers/edit/:id/products/edit/:benefitProductId/templates',
						create: {
							legacy: {
								path: '/benefit-providers/edit/:id/products/edit/:benefitProductId/templates/create/legacy',
							},
							modular: {
								path: '/benefit-providers/edit/:id/products/edit/:benefitProductId/templates/create/:templateLayoutId',
							},
						},
						edit: {
							legacy: {
								root: '/benefit-providers/edit/:id/products/edit/:benefitProductId/templates/edit/legacy/:productTemplateId',
								details: {
									path: '/benefit-providers/edit/:id/products/edit/:benefitProductId/templates/edit/legacy/:productTemplateId/details',
									localTabPath: 'details',
								},
								marketingContents: {
									path: '/benefit-providers/edit/:id/products/edit/:benefitProductId/templates/edit/legacy/:productTemplateId/marketingContents',
									localTabPath: 'marketingContents',
								},
							},
							modular: {
								root: '/benefit-providers/edit/:id/products/edit/:benefitProductId/templates/edit/:productTemplateId',
								details: {
									path: '/benefit-providers/edit/:id/products/edit/:benefitProductId/templates/edit/:productTemplateId/details',
									localTabPath: 'details',
								},
								marketingContents: {
									path: '/benefit-providers/edit/:id/products/edit/:benefitProductId/templates/edit/:productTemplateId/marketingContents',
									localTabPath: 'marketingContents',
								},
							},
						},
					},
				},
			},
			details: {
				localTabPath: 'details',
				path: '/benefit-providers/edit/:id/details',
			},
			externalBankAccounts: {
				localTabPath: 'external-bank-accounts',
				path: '/benefit-providers/edit/:id/external-bank-accounts',
			},
			ftpConfigurations: {
				localTabPath: 'ftp-configurations',
				path: '/benefit-providers/edit/:id/ftp-configurations',
			},
			root: '/benefit-providers/edit/:id',
			settings: {
				localTabPath: 'settings',
				path: '/benefit-providers/edit/:id/settings',
			},
		},
	},

	clientMonitoring: {
		root: '/client-monitoring',
		viewPage: {
			root: '/client-monitoring/view',
		},
	},
	constituents: {
		search: {
			listing: '/constituent-search',
			profile: {
				censusHistory: {
					localTabPath: 'census-history',
					path: '/constituent-search/tenant/:tenantName/profile/:id/census-history',
				},
				diagnostics: {
					localTabPath: 'constituent-diagnostics',
					path: '/constituent-search/tenant/:tenantName/profile/:id/constituent-diagnostics',
				},
				enrollments: {
					localTabPath: 'constituent-enrollments',
					path: '/constituent-search/tenant/:tenantName/profile/:id/constituent-enrollments',
					enrollmentHistory: {
						// eslint-disable-next-line max-len
						path: '/constituent-search/tenant/:tenantName/profile/:id/constituent-enrollments/:workerId/enrollment-history/:planId/:corestreamPolicyId?',
					},
				},
				path: '/constituent-search/tenant/:tenantName/profile/:id',
				requestAmountHistory: {
					localTabPath: 'request-amount-history',
					path: '/constituent-search/tenant/:tenantName/profile/:id/request-amount-history',
				},
				summary: {
					localTabPath: 'summary',
					path: '/constituent-search/tenant/:tenantName/profile/:id/summary',
				},
				transactionHistory: {
					localTabPath: 'constituent-transaction-history',
					path: '/constituent-search/tenant/:tenantName/profile/:id/constituent-transaction-history',
				},
			},
		},
	},
	corestreamConfigurations: {
		root: '/corestream-configurations',
		bankAccounts: {
			localTabPath: 'bank-accounts',
			path: '/corestream-configurations/bank-accounts',
		},
	},
	discounters: {
		path: '/discounters',
		create: {
			path: '/discounters/create',
		},
		edit: {
			path: '/discounters/edit/:discounterId',
			offers: {
				list: {
					path: '/discounters/edit/:discounterId/discount-offers',
				},
				edit: {
					path: '/discounters/edit/:discounterId/discount-offers/edit/:discountOfferId',
				},
				create: {
					path: '/discounters/edit/:discounterId/discount-offers/create/:discountOfferId?',
				},
			},
		},
	},
	employeeSearch: {
		path: '/employee-search',
		tenant: {
			profile: {
				path: '/employee-search/tenant/:tenant/profile/:id',
				details: {
					localTabPath: 'details',
					path: '/employee-search/tenant/:tenant/profile/:id/details',
				},
				enrollments: {
					localTabPath: 'enrollments',
					path: '/employee-search/tenant/:tenant/profile/:id/enrollments',
					create: {
						path: '/employee-search/tenant/:tenant/profile/:workerId/enrollments/create/:benefitPlanId',
					},
				},
				censusHistory: {
					localTabPath: 'census-history',
					path: '/employee-search/tenant/:tenant/profile/:id/census-history',
				},
				employeeFileHistory: {
					localTabPath: 'employee-file-history',
					path: '/employee-search/tenant/:tenant/profile/:id/employee-file-history',
				},
			},
		},
	},
	fileMonitoring: {
		root: '/file-monitoring',
		unprocessed: {
			localTabPath: 'unprocessed',
			get path() {
				return `${routePaths.fileMonitoring.root}/unprocessed` as const
			},
		},
		overdue: {
			localTabPath: 'overdue',
			get path() {
				return `${routePaths.fileMonitoring.root}/overdue` as const
			},
		},
		inboundCensus: {
			localTabPath: 'inbound-census',
			get path() {
				return `${routePaths.fileMonitoring.root}/inbound-census` as const
			},
			file: {
				get path() {
					return `${routePaths.fileMonitoring.inboundCensus.path}/:id` as const
				},
				success: {
					get path() {
						return `${routePaths.fileMonitoring.inboundCensus.file.path}/success` as const
					},
				},
				errors: {
					get path() {
						return `${routePaths.fileMonitoring.inboundCensus.file.path}/errors` as const
					},
				},
				warnings: {
					get path() {
						return `${routePaths.fileMonitoring.inboundCensus.file.path}/warnings` as const
					},
				},
				missing: {
					get path() {
						return `${routePaths.fileMonitoring.inboundCensus.file.path}/missing` as const
					},
				},
			},
		},
		outboundCensus: {
			localTabPath: 'outbound-census',
			get path() {
				return `${routePaths.fileMonitoring.root}/outbound-census` as const
			},
			file: {
				get path() {
					return `${routePaths.fileMonitoring.outboundCensus.path}/:id` as const
				},
				success: {
					get path() {
						return `${routePaths.fileMonitoring.outboundCensus.file.path}/success` as const
					},
				},
				errors: {
					get path() {
						return `${routePaths.fileMonitoring.outboundCensus.file.path}/errors` as const
					},
				},
			},
		},
		inboundElections: {
			localTabPath: 'inbound-elections',
			get path() {
				return `${routePaths.fileMonitoring.root}/inbound-elections` as const
			},
			file: {
				get path() {
					return `${routePaths.fileMonitoring.inboundElections.path}/:fileId` as const
				},
				lineDetails: {
					get path() {
						return `${routePaths.fileMonitoring.inboundElections.file.path}/:status` as const
					},
				},
			},
		},
		outboundElections: {
			localTabPath: 'outbound-elections',
			get path() {
				return `${routePaths.fileMonitoring.root}/outbound-elections` as const
			},
			file: {
				get path() {
					return `${routePaths.fileMonitoring.outboundElections.path}/:electionFileId` as const
				},
				lineDetails: {
					get path() {
						return `${routePaths.fileMonitoring.outboundElections.file.path}/:lineStatusType` as const
					},
				},
			},
		},
		providerBills: {
			localTabPath: 'provider-bills',
			get path() {
				return `${routePaths.fileMonitoring.root}/provider-bills` as const
			},
			file: {
				get path() {
					return `${routePaths.fileMonitoring.providerBills.path}/:fileId` as const
				},
				lineDetails: {
					get path() {
						return `${routePaths.fileMonitoring.providerBills.file.path}/:status` as const
					},
				},
			},
		},
		providerRemittance: {
			localTabPath: 'provider-remittance',
			get path() {
				return `${routePaths.fileMonitoring.root}/provider-remittance` as const
			},
			file: {
				get path() {
					return `${routePaths.fileMonitoring.providerRemittance.path}/:remittanceFileId` as const
				},
			},
		},
		payrollDeduction: {
			localTabPath: 'payroll-deduction-summary',
			get path() {
				return `${routePaths.fileMonitoring.root}/payroll-deduction-summary` as const
			},
			file: {
				get path() {
					return `${routePaths.fileMonitoring.payrollDeduction.path}/:fileId` as const
				},
				lineDetails: {
					get path() {
						return `${routePaths.fileMonitoring.payrollDeduction.file.path}/details` as const
					},
				},
			},
		},
		payrollConfirmation: {
			localTabPath: 'payroll-confirmation-summary',
			get path() {
				return `${routePaths.fileMonitoring.root}/payroll-confirmation-summary` as const
			},
			file: {
				get path() {
					return `${routePaths.fileMonitoring.payrollConfirmation.path}/:fileId` as const
				},
				fileLineDetails: {
					get path() {
						return `${routePaths.fileMonitoring.payrollConfirmation.file.path}/details` as const
					},
				},
			},
		},
		providerConfirmation: {
			localTabPath: 'provider-confirmation-summary',
			get path() {
				return `${routePaths.fileMonitoring.root}/provider-confirmation-summary` as const
			},
			file: {
				get path() {
					return `${routePaths.fileMonitoring.providerConfirmation.path}/:fileId` as const
				},
				lineDetails: {
					get path() {
						return `${routePaths.fileMonitoring.providerConfirmation.file.path}/details` as const
					},
				},
			},
		},
	},
	invoices: {
		details: '/invoices/:id',
		discrepancies: '/invoices/:id/discrepancies',
		listing: '/invoices',
		remittance: {
			path: '/invoices/:id/remittanceDetails',
		},
		payrollPayments: {
			details: '/invoices/:id/payroll-payments',
		},
	},
	organizations: {
		create: {
			path: '/organizations/create',
			localTabPath: 'create',
		},
		edit: {
			root: '/organizations/edit/:id',
			details: {
				localTabPath: 'details',
				path: '/organizations/edit/:id/details',
			},
			faq: {
				localTabPath: 'faqs',
				path: '/organizations/edit/:id/faqs',
			},
			benefitPlans: {
				localTabPath: 'benefit-plans',
				path: '/organizations/edit/:id/benefit-plans',
				details: {
					localTabPath: 'details',
					path: '/organizations/edit/:id/benefit-plans/edit/:benefitPlanId/details',
				},
				marketingContent: {
					localTabPath: 'marketing-content',
					path: '/organizations/edit/:id/benefit-plans/edit/:benefitPlanId/marketing-content',
					create: {
						path: '/organizations/edit/:id/benefit-plans/edit/:benefitPlanId/marketing-content/create',
					},
					edit: {
						path: '/organizations/edit/:id/benefit-plans/edit/:benefitPlanId/marketing-content/edit/:marketingContentId',
					},
				},
				edit: {
					path: '/organizations/edit/:id/benefit-plans/edit/:benefitPlanId',
				},
				create: {
					path: '/organizations/edit/:id/benefit-plans/create',
				},
				rates: {
					localTabPath: 'rates',
					path: '/organizations/edit/:id/benefit-plans/edit/:benefitPlanId/rates',
					modify: {
						path: '/organizations/edit/:id/benefit-plans/edit/:benefitPlanId/rates/modify/:rateInfoId',
					},
					details: {
						path: '/organizations/edit/:id/benefit-plans/edit/:benefitPlanId/rates/details/:rateInfoId',
						table: {
							path: '/organizations/edit/:id/benefit-plans/edit/:benefitPlanId/rates/details/:rateInfoId/table',
							edit: {
								path: '/organizations/edit/:id/benefit-plans/edit/:benefitPlanId/rates/details/:rateInfoId/table/edit',
							},
							localTabPath: 'table',
						},
						content: {
							path: '/organizations/edit/:id/benefit-plans/edit/:benefitPlanId/rates/details/:rateInfoId/content',
							localTabPath: 'content',
						},
						eoiSettings: {
							path: '/organizations/edit/:id/benefit-plans/edit/:benefitPlanId/rates/details/:rateInfoId/eoi-settings',
							localTabPath: 'eoi-settings',
						},
						highlights: {
							path: '/organizations/edit/:id/benefit-plans/edit/:benefitPlanId/rates/details/:rateInfoId/highlights',
							localTabPath: 'highlights',
						},
					},
					edit: {
						path: '/organizations/edit/:id/benefit-plans/edit/:benefitPlanId/rates/edit/:rateInfoId',

						details: {
							localTabPath: 'details',
							path: '/organizations/edit/:id/benefit-plans/edit/:benefitPlanId/rates/edit/:rateInfoId/details',
						},
						highlights: {
							localTabPath: 'highlights',
							path: '/organizations/edit/:id/benefit-plans/edit/:benefitPlanId/rates/edit/:rateInfoId/highlights',
						},
					},
					create: {
						path: '/organizations/edit/:id/benefit-plans/edit/:benefitPlanId/rates/rateInfo/create',
					},
					clone: {
						path: '/organizations/edit/:id/benefit-plans/edit/:benefitPlanId/rates/rateInfo/clone/:rateInfoId',
					},
				},
			},
			openEnrollment: {
				localTabPath: 'open-enrollment',
				path: '/organizations/edit/:id/open-enrollment',
				edit: {
					path: '/organizations/edit/:id/open-enrollment/edit/:oeWindowId',
				},
				create: {
					path: '/organizations/edit/:id/open-enrollment/create',
				},
			},
			inboundCensus: {
				localTabPath: 'inbound-census',
				path: '/organizations/edit/:id/inbound-census',
			},
			providers: {
				localTabPath: 'providers',
				path: '/organizations/edit/:id/providers',
				edit: {
					path: '/organizations/edit/:id/providers/:providerId',
					configuration: {
						localTabPath: 'configuration',
						path: '/organizations/edit/:id/providers/:providerId/configuration',
					},
					billFileConfiguration: {
						path: '/organizations/edit/:id/providers/:providerId/bill-file-configuration',
						localTabPath: 'bill-file-configuration',
						create: {
							path: '/organizations/edit/:id/providers/:providerId/bill-file-configuration/create',
						},
						update: {
							path: '/organizations/edit/:id/providers/:providerId/bill-file-configuration/update/:configurationId',
						},
					},
					outboundElectionConfiguration: {
						path: '/organizations/edit/:id/providers/:providerId/outbound-election-configs',
						localTabPath: 'outbound-election-configs',
						create: {
							path: '/organizations/edit/:id/providers/:providerId/outbound-election-configs/create',
						},
						update: {
							path: '/organizations/edit/:id/providers/:providerId/outbound-election-configs/update/:configurationId',
						},
					},
					providerConfirmationConfiguration: {
						path: '/organizations/edit/:id/providers/:providerId/provider-confirmation-configs',
						localTabPath: 'provider-confirmation-configs',
						create: {
							path: '/organizations/edit/:id/providers/:providerId/provider-confirmation-configs/create',
						},
						update: {
							path: '/organizations/edit/:id/providers/:providerId/provider-confirmation-configs/update/:configurationId',
						},
					},
					outboundCensus: {
						path: '/organizations/edit/:id/providers/:providerId/outbound-census',
						localTabPath: 'outbound-census',
					},
				},
			},
			payrollDeductionConfigs: {
				localTabPath: 'payroll-deduction-configs',
				path: '/organizations/edit/:id/payroll-deduction-configs',
			},
			payrollConfirmationConfigs: {
				localTabPath: 'payroll-confirmation-configs',
				path: '/organizations/edit/:id/payroll-confirmation-configs',
			},
			financials: {
				localTabPath: 'financials',
				path: '/organizations/edit/:id/financials',
			},
			ftpConfigurations: {
				path: '/organizations/edit/:id/ftp-configurations',
				localTabPath: 'ftp-configurations',
			},
			inboundElectionFileConfigs: {
				localTabPath: 'inbound-election-configs',
				path: '/organizations/edit/:id/inbound-election-configs',
			},
			flags: {
				localTabPath: 'organization-flags',
				path: '/organizations/edit/:id/organization-flags',
			},
			deductionGroups: {
				localTabPath: 'deduction-groups',
				get path() {
					return `${routePaths.organizations.edit.root}/deduction-groups` as const
				},
				schedules: {
					get path() {
						return `${routePaths.organizations.edit.deductionGroups.path}/:payGroupId/schedules` as const
					},
				},
			},
			qleEvents: {
				path: '/organizations/edit/:id/qle-events',
				to: (id: string) => `/organizations/edit/${id}/qle-events`,
				localTabPath: 'qle-events',
			},
		},
		root: '/organizations',
	},
	productionSupport: {
		root: '/production-support',
		deductionRequest: {
			path: '/production-support/deduction-request',
			details: {
				path: '/production-support/deduction-request/details',
			},
			bulk: {
				path: '/production-support/deduction-request/bulk',
				edit: {
					path: '/production-support/deduction-request/bulk/edit',
				},
			},
		},
		enrollmentLedger: {
			path: '/production-support/enrollment-ledger',
		},
		enrollmentSearch: {
			path: '/production-support/enrollment-search',
			details: {
				path: '/production-support/enrollment-search/details',
			},
		},
	},

	refunds: {
		root: '/refunds',
	},
	productTypes: {
		edit: {
			root: '/product-types/edit/:corestreamProductId',
			tokens: {
				path: '/product-types/edit/:corestreamProductId/tokens',
				localTabPath: 'tokens',
			},
			details: {
				path: '/product-types/edit/:corestreamProductId/details',
				localTabPath: 'details',
			},
		},
		root: '/product-types',
	},
} as const
