import { MutableRefObject, ReactNode } from 'react'
import { createWithEqualityFn as create } from 'zustand/traditional'

export type CrumbStore = {
	crumbs: Record<string, MutableRefObject<HTMLDivElement | null>>
	updateCrumb: (name: string, ref: MutableRefObject<HTMLDivElement | null>) => void
	removeCrumb: (name: string) => void
}

export type UseCrumbRegistrationReturn = {
	updateCrumbValue: (crumbValue: ReactNode) => void
	CrumbPortalRef: MutableRefObject<ReactNode | null>
}

export const useCrumbStore = create<CrumbStore>()((set) => ({
	crumbs: {},
	updateCrumb: (name, ref) => {
		set((state) => ({ crumbs: { ...state.crumbs, [name]: ref } }))
	},
	removeCrumb: (name) =>
		set((state) => {
			const crumbs = { ...state.crumbs }
			delete crumbs[name]

			return { crumbs }
		}),
}))
