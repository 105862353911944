import { useFlags } from 'launchdarkly-react-client-sdk'
import { Link, Navigate, UIMatch, generatePath } from 'react-router-dom'
import { DynamicBreadcrumb } from 'routes/breadcrumbs'
import { routePaths } from 'routes/route-paths'
import { useOrgBenefitPlanEditRateNestedRoutes } from 'routes/useOrgBenefitPlanEditRateNestedRoutes'
import { prepareRoutes } from 'routes/utils/prepareRoutes'

export const useBenefitPlanRatesRoutes = () => {
	const { und18564_plan_rates_v2 } = useFlags()

	const routesNew = prepareRoutes(
		[
			{
				path: '*',
				lazy: () =>
					import('pages/rates').then((module) => ({
						Component: module.default,
					})),
			},
			{
				flag: und18564_plan_rates_v2,
				path: routePaths.organizations.edit.benefitPlans.rates.modify.path,
				handle: {
					crumb: (data: UIMatch<any, any>) => {
						return <DynamicBreadcrumb portalId={data.params.rateInfoId ?? ''} />
					},
				},
				lazy: () => import('features/plan-rates'),
			},
			{
				flag: und18564_plan_rates_v2,
				path: routePaths.organizations.edit.benefitPlans.rates.details.path,
				lazy: () => import('pages/rates/details/rates-details-page'),
				handle: {
					crumb: (data: UIMatch<any, any>) => {
						return <DynamicBreadcrumb portalId={data.params.rateInfoId ?? ''} />
					},
				},
				children: prepareRoutes(
					[
						{
							index: true,
							element: <Navigate to={'table'} replace />,
						},
						{
							path: routePaths.organizations.edit.benefitPlans.rates.details.table.path,
							lazy: () => import('pages/rates/details/tabs/rates-table-page'),
							handle: {
								crumb: (data: UIMatch<any, any>) => {
									return (
										<Link
											to={generatePath(routePaths.organizations.edit.benefitPlans.rates.details.table.path, {
												benefitPlanId: data.params.benefitPlanId ?? '',
												rateInfoId: data.params.rateInfoId ?? '',
												id: data.params.id ?? '',
											})}
										>
											Table
										</Link>
									)
								},
							},
						},
						{
							path: routePaths.organizations.edit.benefitPlans.rates.details.content.path,
							lazy: () => import('pages/rates/details/tabs/rates-content-page'),
							handle: {
								crumb: (data: UIMatch<any, any>) => {
									return (
										<Link
											to={generatePath(routePaths.organizations.edit.benefitPlans.rates.details.content.path, {
												benefitPlanId: data.params.benefitPlanId ?? '',
												rateInfoId: data.params.rateInfoId ?? '',
												id: data.params.id ?? '',
											})}
										>
											Content
										</Link>
									)
								},
							},
						},
						{
							path: routePaths.organizations.edit.benefitPlans.rates.details.eoiSettings.path,
							lazy: () => import('pages/rates/details/tabs/rates-eoi-settings-page'),
							handle: {
								crumb: (data: UIMatch<any, any>) => {
									return (
										<Link
											to={generatePath(routePaths.organizations.edit.benefitPlans.rates.details.eoiSettings.path, {
												benefitPlanId: data.params.benefitPlanId ?? '',
												rateInfoId: data.params.rateInfoId ?? '',
												id: data.params.id ?? '',
											})}
										>
											EOI Settings
										</Link>
									)
								},
							},
						},
						{
							path: routePaths.organizations.edit.benefitPlans.rates.details.highlights.path,
							lazy: () => import('pages/rates/details/tabs/rates-plan-highlights-page'),
							handle: {
								crumb: (data: UIMatch<any, any>) => {
									return (
										<Link
											to={generatePath(routePaths.organizations.edit.benefitPlans.rates.details.highlights.path, {
												benefitPlanId: data.params.benefitPlanId ?? '',
												rateInfoId: data.params.rateInfoId ?? '',
												id: data.params.id ?? '',
											})}
										>
											Plan Highlights
										</Link>
									)
								},
							},
						},
					],
					{
						stripLeading: `${routePaths.organizations.edit.benefitPlans.rates.details.path}/`,
					},
				),
			},
			{
				flag: und18564_plan_rates_v2,
				path: routePaths.organizations.edit.benefitPlans.rates.details.table.edit.path,
				lazy: () => import('pages/rates/details/edit-rates-table-page'),
			},
			{
				flag: !und18564_plan_rates_v2,
				lazy: () =>
					import('routes/edit-rates-routes').then((module) => ({
						Component: module.default,
					})),
				path: routePaths.organizations.edit.benefitPlans.rates.edit.path,
				children: useOrgBenefitPlanEditRateNestedRoutes(),
				handle: {
					crumb: (data: UIMatch<any, any>) => {
						return <DynamicBreadcrumb portalId={data.params.rateInfoId ?? ''} />
					},
				},
			},
			{
				flag: !und18564_plan_rates_v2,
				lazy: () =>
					import('pages/create-rate-info').then((module) => ({
						Component: module.default,
					})),
				path: routePaths.organizations.edit.benefitPlans.rates.create.path,
			},
			{
				flag: und18564_plan_rates_v2,
				path: routePaths.organizations.edit.benefitPlans.rates.create.path,
				handle: {
					crumb: (data: UIMatch<any, any>) => (
						<Link
							to={generatePath(routePaths.organizations.edit.benefitPlans.rates.create.path, {
								benefitPlanId: data.params.benefitPlanId ?? '',
								id: data.params.id ?? '',
							})}
						>
							New Rates
						</Link>
					),
				},
				lazy: () => import('features/plan-rates'),
			},
		],
		{
			stripLeading: `${routePaths.organizations.edit.benefitPlans.rates.path}/`,
		},
	)

	return routesNew
}
