import { CssVarsThemeOptions, alpha } from '@mui/material/styles'

// ----------------------------------------------------------------------

export function paper(): CssVarsThemeOptions['components'] {
	return {
		MuiPaper: {
			styleOverrides: {
				root: {
					backgroundImage: 'none',
					boxShadow: 'var(--pillow-low)',
				},
				outlined: ({ theme }) => ({
					borderColor: alpha(theme.palette.grey[500], 0.16),
				}),
			},
		},
	}
}
